import React from 'react';
import styled from 'styled-components';
import { fetchUrl } from '../apiClient/baseApi';
import { withTranslation } from 'gatsby-plugin-react-i18next';

const ConStyles = styled.nav`

body {
 background: #1d1e21;
 font-size: 3.5vw;
 @media (min-width: 900px) {
  font-size: 2vw;
 }
}

section {
 background: white;
 padding: 0.5em;
 border-radius: 0.5em;
 box-shadow: 4px 4px 50px rgba(#687bf7, 0);
 transition: box-shadow 100ms linear;
 text-align: center !important;
 &:hover {
  box-shadow: 4px 4px 50px rgba(#687bf7, 0.6);
 }
}

.react-confirm-alert-button-group {
  justify-content: center !important;
}

`;


class Cr8Content extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      conTitle: "",
      conLang: this.props.defLang,
      conType: this.props.conType,
      langOptions: []
     };
  }

  componentDidMount() {
      this.loadLanguages()
  }

  loadLanguages = async () => {
    await fetchUrl('get', '/items/language').then( res => {
      this.setState({
        langOptions: res.data
      }); 
    })
  }

   handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

   handleSubmit = event => {
    event.preventDefault()
    this.props.addnewCon(this.state);
  }

  render() {
    const { t } = this.props;

    const buttonStyle1 = {
      margin: '1rem',
      background: '#7D59C2',
      fontSize: 15
      }

      const buttonStyle = {
        margin: '1rem',
        fontSize: 15
      }
    return(

        <ConStyles> 
        <section>
        <h2>{t('link17')}</h2>
        <form onSubmit={this.handleSubmit}>
        <label>
          <input
            style={buttonStyle} 
            type="text"
            name="conTitle"
            value={this.state.conTitle}
            onChange={this.handleInputChange}
            placeholder={t('link18')}
          />
        </label>
         
        <label >
          <select style={buttonStyle} name="conLang" value={this.state.conLang} onChange={this.handleInputChange}>
            <option>{t('link19')}</option>
            {this.state.langOptions.map((e, key) => {
                                return <option key={key} value={e.id}>{e.name}</option>;
                            })}
          </select>
        </label>
        <div className="react-confirm-alert-button-group">
          <button style={buttonStyle1} type="submit">{t('link21')}</button>
          <button style={buttonStyle} onClick={this.props.close}>{t('link20')}</button>
        </div>
      </form>
        </section>
        </ConStyles>

    );
  }
}

export default withTranslation()(Cr8Content);