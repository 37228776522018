import styled from 'styled-components';

const NavStyles = styled.nav`
#bookTitle {
  padding-right: 10px;
  padding-left: 10px;
  max-width: 430px;
  overflow: hidden;
  height: 33px;
  white-space: nowrap;
  &:hover {
    outline: 1px ridge white;
   }
  &:focus-visible {
    outline: 1px auto white;
  }
}
ul {
  margin: 0;
  padding: 10px 20px;
  text-align: center;
  align-items: center;
  list-style: none;
  display: grid;
  grid-gap:1.3rem;
  grid-template-columns: auto auto auto auto 1fr auto auto;
  background: white;
  color: black;
  height: 70px;
  border-bottom: 2px solid whitesmoke;

  li {
    height: 35px;
    line-height: 33px;
  }
  li a, li button {
    display: inline-block;
    color: black;
    text-align: center;
    text-decoration: none;
  }

  li button {
    background-color: purple;
    border: none;
    box-shadow: none;
    text-shadow: none;
  }
  
  li.dropdown {
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    top: 50px;
  }

  .settings-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    left: 0;
    top: 40px;
  }

  .dropdown-content a, .settings-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: start;
  }
  
  .dropdown-content a:hover, .settings-content a:hover {
    background-color: #f1f1f1;
    cursor: pointer;
  }
  
  .dropdown-toggle {
    display: block;
  }

  .dropdown-toggle::after {
    display: none;
  }
}

.languages ul {
  grid-template-columns: auto auto auto auto auto auto auto;
  height: 70%;
}

.languages a:hover {
  color: #FF5B39;
}

.linkStyle1 {
  border-radius: .2em;
  padding-left: 15px;
  padding-right: 15px;
  &:hover {
    background: whitesmoke;
    color: black;
    cursor: pointer;
   }
}

.linkStyle2 {
  border-radius: .2em;
  padding-left: 15px;
  padding-right: 15px;
  color: black;
  width: 100%;
  text-align: start;
  display: block;
  background: white;
  &:hover {
    cursor: pointer;
    background: whitesmoke;
   }
}

.dropbtn, .profbtn {
  background: #FF5B39;
  text-decoration: none;
  color: black;
  border-radius: .2em;
  padding-left: 15px;
  padding-right: 15px;
  &:hover {
    color: whitesmoke;
    cursor: pointer;
   }
}

.linebtn {
  background: white;
  text-decoration: none;
  color: black;
  border: solid 1px black;
  border-radius: .2em;
  padding-left: 15px;
  padding-right: 15px;
  &:hover {
    color: #FF5B39;
    cursor: pointer;
   }
}

.profbtn {
  border-radius: 50%;
}

hr {
  margin: 0;
  color: grey;
}

#navBar {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 70px;
  z-index: 5;
}

.main-header ul {
  grid-template-columns: auto auto auto auto auto auto auto;
  border-bottom: none;
}

#proModal, #profileModal, #pubModal, #langModal {
	display: none; /* Hidden by default */
  overflow: auto;
  }

/* Modal Content */
.langmodal-content {
margin: 10% auto;
padding: 20px;
width: 50%;
height: auto;
}

.react-confirm-alert-body {
  text-align: start !important; 
}

`;

export default NavStyles;