import { createGlobalStyle } from 'styled-components';
import bg from '../assets/images/STARS.png';

const GlobalStyles = createGlobalStyle`
  :root {
    --orange: #FF5B39;
    --black: #2E2E2E;
    --purple: #7D59C2;
    --white: #fff;
    --grey: #efefef;
    --green: #B4D9B6;
  }

  html {
    background-image: url(${bg});
    background-size: 450px;
    background-attachment: fixed;
  }

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

  button {
    background: var(--purple);
    color: white;
    border: 0;
    border-radius: 2px;
    cursor: pointer;
    --cast: 2px;
    box-shadow: none;
    text-shadow: none;
    transition: all 0.2s;
    &:hover {
      --cast: 4px;
    }
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  /* Scrollbar Styles 
  body::-webkit-scrollbar {
    width: 0.8em;
  }
  html {
    scrollbar-width: auto;
    scrollbar-color: var(--red) var(--white);
  }
  body::-webkit-scrollbar-track {
    background: white;
  }
  
  body::-webkit-scrollbar-thumb {
    background-color:  ;
    border-radius: 0.5em;
    border: 3px solid var(--white);
  }
  */

 body {
  overflow-y: scroll;
 }
  hr {
    border: 0;
    height: 8px;
    background-image: url(${bg});
    background-size: 1500px;
  }

  img {
    max-width: 100%;
  }

  .tilt {
    transform: rotate(-2deg);
    position: relative;
    display: inline-block;
  }

  .toast {
    font-size: 1rem;
    opacity: 2;
  }

  .react-icons {
    vertical-align: middle;
  }


#proModal, #profileModal, #pubModal, #langModal {
	display: none; /* Hidden by default */
  overflow: auto;
  }

  span.remove {
    color: var(--orange);
    font-size: 3rem !important;
    cursor: pointer;
    margin-top: -70px;
    margin-right: -50px;
}

[dir=rtl] span.remove {
	float: left;
}

[dir=ltr] span.remove {
	float: right;
}

  footer, .siteStyl {
    h1 {
      font-size: 40px;
    }
  
    h2 {
      font-size: 28px;
    }
  
    h3 {
      font-size: 24px;
    }
  
    h4, h5, h6 {
      font-size: 19px;
    }
  
  
    a {
      text-decoration: none;
      color: var(--orange);
    }
  
    a:hover {
      text-decoration: none;
      color: var(--orange);
    }
  
    h1, h2, h3, h4, h5, h6 {
      font-family: "Bebas Neue";
    }
  
    p, a {
      font-family: "Work Sans";
    }
 
  }
  
[dir=rtl] .dropdown-content {
    left: 0;
    right: unset;
}

[dir=ltr] .dropdown-content {
    right: 0;
    left: unset;
}
  
.react-confirm-alert-overlay {
  background: rgb(103 103 103 / 90%) !important;
}

.react-confirm-alert-button-group {
  justify-content: center !important;
}

.react-confirm-alert-body {
  text-align: start !important; 
}
`;

export default GlobalStyles;
