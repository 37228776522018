import React from 'react';
import NavStyles from '../styles/NavStyles';
import {Link, useI18next} from 'gatsby-plugin-react-i18next';
import { StaticImage } from "gatsby-plugin-image";
import { GrLanguage } from "react-icons/gr";
import Header from './Header';

export default function Nav({ lang }) {
    const { navigate, t } = useI18next();

    const [state, setState] = React.useState({ showModal: false });
    const handleOpenModal = () => {
        setState({ showModal: true });
    }

    const handleCloseModal = () => {
        setState({ showModal: false });
    }

return (<><NavStyles>
    <ul className="flex siteStyl" id="navBar">
        <li style={{fontFamily: "Bebas Neue"}}>
           <StaticImage src="../assets/images/ourlogo.png" alt="Magic Author" style={{width: "31.5px", height: "39.5px"}} imgStyle={{width: "31.5px", height: "39.5px"}} />MAGIC AUTHOR<sup>&reg;</sup>
         </li>
        <li>
            <Link to="/" >
                <div className="linkStyle1">
                {t('link1')}
                </div>
            </Link>
        </li>
        <li className="dropdown">
            <div className="linkStyle1" onClick={() => {
				   var modal = document.getElementById("langModal");
				   modal.style.display = "block";
				}}>
                <GrLanguage /> {lang} &#119119;
            </div>
        </li>
        <li> 
        
        </li>
        <li>
            
        </li>
        <li className="dropdown">
            <button className="linebtn" onClick={() => navigate("/login")}>
                {t('link2')}
            </button>
        </li>
        <li className="dropdown">
            <button className="dropbtn" onClick={() => navigate("/register")}>
                {t('link3')}
            </button>
        </li> 
    </ul>
    <div id="langModal" className="react-confirm-alert-overlay siteStyl" role="presentation" onClick={(event) => {
                let modl = document.getElementById("langModal");
                 if (event.target === modl) {
                    modl.style.display = "none";
                  }
             }}>
                <div className="react-confirm-alert-body langmodal-content">
                    <span className="remove" role="presentation" onClick={() => {
                        let modl = document.getElementById("langModal");
                        modl.style.display = "none";
                    }}>&times;</span>
                    <p>Choose your language</p>
                    <Header close={handleCloseModal} />
                </div>
    </div>
    </NavStyles>
    </>
)

}
