import React from 'react'
import {Link, useI18next} from 'gatsby-plugin-react-i18next';
import useAuth from '../hooks/useAuth'
import NavStyles from '../styles/NavStyles';
import ReactModal from 'react-modal';
import Cr8Content from './Cr8Content';
import { fetchUrl } from '../apiClient/baseApi';
import { BsFileText, BsNewspaper } from "react-icons/bs";
import { GrLanguage } from "react-icons/gr";
import { RiBook2Line, RiAccountCircleLine } from "react-icons/ri";
import { AiOutlineMail, AiOutlineLogout } from "react-icons/ai";
import { StaticImage } from "gatsby-plugin-image";
import logo from "../assets/images/logo.png";
import Header from './Header';


const initialModalState = {
  showebookModal: false,
  showmagazineModal: false,
  showarticleModal: false,
  shownewsletterModal: false,
  showModal: false
 }

 const licPage = '<p>All rights reserved. Copyright infringement is against the law. This book or any portion ' + 
'thereof may not be reproduced or used in any manner whatsoever without the express written permission of the author/publisher ' + 
'except for the use of brief quotations in a book review.</p>' + 
'<p>Portions of this book are works of fiction. Any references to historical events, real people, or real places are used ' + 
'fictitiously. Other names, characters, places and events are products of the author\'s imagination, and any resemblances ' + 
'to actual events or places or persons, living or dead, is entirely coincidental.</p>' 

const titFiller = '<h2><br /></h2>'

export default function SignedNav({ lang }) {
  const { t, i18n, navigate } = useI18next();
  const direc = i18n.dir()

  const handleOpenModal = () => {
      setState({ showModal: true });
  }

  const handleCloseModal = () => {
      setState({ showModal: false });
  }

  const { isAuthenticated, logout } = useAuth()
  const [state, setState] = React.useState(initialModalState);
  const [user, setUser] = React.useState([]); 
  const [langBE, setLangBE] = React.useState(1)


  function toggleBtn(ele) {
    var elist = ['btnCntnt', 'lgout'];
    elist.forEach(element => {
      if (element !== ele) {
        document.getElementById(element).classList.remove("dropdown-toggle");
      }
    });
    document.getElementById(ele).classList.toggle("dropdown-toggle");
  }

  const LinkStyle = {
    textDecoration: "none",
    color: "black",
  }

  const handleebookOpenModal = () => {
      setState({ showebookModal: true });
  }

  const handlearticleOpenModal = () => {
  setState({ showarticleModal: true });
  }

  const handlemagazineOpenModal = () => {
  setState({ showmagazineModal: true });
  }

  const handlenewsletterOpenModal = () => {
    setState({ shownewsletterModal: true });
    }  

  const handleebookCloseModal = () => {
      setState({ showebookModal: false });      
  }

  const handlearticleCloseModal = () => {
  setState({ showarticleModal: false });
  }

  const handlemagazineCloseModal = () => {
  setState({ showmagazineModal: false });
  }

  const handlenewsletterCloseModal = () => {
    setState({ shownewsletterModal: false });
    }

  const cr8newCon = async (newCon) => {
    let year = new Date().getFullYear();
  
    if (newCon.conType === 'ebook') {
    let bookData = { "title": newCon.conTitle, "language" : newCon.conLang }
    let chpData = []
    let titlePage = titFiller.repeat(4) + '<h2>' + newCon.conTitle + '</h2>' + 
                      titFiller.repeat(10) + '<h2>' + user.first_name + ' ' + user.last_name + '</h2'
    let licBody = '<h3>' + newCon.conTitle + '</h3><h3>Copyright &copy; ' + year + ' ' + user.first_name + ' ' + user.last_name + '</h3>' +
                    licPage + '<h3>First Edition: ' + year + '</h3>'
    await fetchUrl('post', '/items/books', bookData).then((res) => {
      chpData.push({ "type": "title", "title": newCon.conTitle, "body": titlePage, "weight" : 1, "bid": res.data.id })
      chpData.push({ "type": "edition", "title": " ", "body": licBody, "weight" : 2, "bid": res.data.id })
      Promise.all(
        chpData.map( async (data) => {
          await fetchUrl('post',  '/items/chapters/' , data)
        })
      ).then(() => {
        fetchUrl('get', '/items/books/' + res.data.id + '?fields=*.*').then((result) => {
          console.log('created the chapters')
          // navigate to the new bookwizard page
          navigate( '/app/bookwizard/' + result.data.id, { state: { book: result.data }, }) 
        setState({ showebookModal: false })
        })
      })
    })
  }
    else if (newCon.conType === 'article') {
      setState({ showarticleModal: false })
    } 
    else if (newCon.conType === 'magazine') {
      setState({ showmagazineModal: false })
    }
    else if (newCon.conType === 'newsletter') {
      setState({ shownewsletterModal: false })
    }
  }

  React.useEffect( ()=>{
    if (isAuthenticated) {
      setUser(JSON.parse(window.localStorage.getItem('user')));
    }
   }, [isAuthenticated])

   React.useEffect(()=>{
    if(user) {
      if (user.pref_lang) {
        let lid
        if (typeof user.pref_lang == "object") {
             lid = user.pref_lang["id"]
        }
      else {
        lid = user.pref_lang
      }
        setLangBE(lid)
      }
    }
   }, [user])

  return (<>
  <NavStyles>
    <ul className="flex siteStyl" id="navBar">
         <li style={{fontFamily: "Bebas Neue"}}>
          <StaticImage src="../assets/images/ourlogo.png" alt="Magic Author" style={{width: "31.5px", height: "39.5px"}} imgStyle={{width: "31.5px", height: "39.5px"}} />MAGIC AUTHOR<sup>&reg;</sup>
         </li>
         <li>
              <Link to="/" style={LinkStyle}>
                <div className="linkStyle1">
                  {t('link1')}
                </div>
              </Link>
          </li>
          <li>
              <Link to="/app" style={LinkStyle}>
                <div className="linkStyle1">
                  {t('link4')}
                </div>
              </Link>    
          </li>
          <li className="dropdown">
          <div className="linkStyle1" onClick={() => {
				   var modal = document.getElementById("langModal");
				   modal.style.display = "block";
				}}>
                  <GrLanguage /> {lang} &#119119;
            </div>
          </li>
          <li>
            
          </li>
          <li className="dropdown">
              <button className="dropbtn" onClick={() => toggleBtn("btnCntnt")}>
                {t('link5')}
                </button>
                <div id="btnCntnt" className="dropdown-content" dir={direc}>
                  <button className="linkStyle2" onClick={handleebookOpenModal}><RiBook2Line />  {t('link6')} </button>
                  <ReactModal 
                    isOpen={state.showebookModal}
                    contentLabel="Create New ebook"  
                    onRequestClose={handleebookCloseModal}
                    className="react-confirm-alert-body"
                    overlayClassName="react-confirm-alert-overlay"
                    key="ebook"
                    appElement={document.getElementById('navBar') || undefined}
                  >
                  <Cr8Content conType={"ebook"} close={handleebookCloseModal} addnewCon={cr8newCon} defLang={langBE}/>
                  </ReactModal>
                {/*   <hr />
                  <button className="linkStyle2" onClick={handlearticleOpenModal}><BsFileText />  Article </button>
                  <ReactModal 
                    isOpen={state.showarticleModal}
                    contentLabel="Create New Article"  
                    onRequestClose={handlearticleCloseModal}
                    className="react-confirm-alert-body"
                    overlayClassName="react-confirm-alert-overlay"
                    key="article"
                  >
                  <Cr8Content conType={"article"} close={handlearticleCloseModal} addnewCon={addnewCon} />
                  </ReactModal>
                  <hr />
                  <button className="linkStyle2" onClick={handlemagazineOpenModal}><BsNewspaper />  Magazine </button>
                  <ReactModal 
                    isOpen={state.showmagazineModal}
                    contentLabel="Create New Magazine"  
                    onRequestClose={handlemagazineCloseModal}
                    className="react-confirm-alert-body"
                    overlayClassName="react-confirm-alert-overlay"
                    key="magazine"
                  >
                  <Cr8Content conType={"magazine"} close={handlemagazineCloseModal} addnewCon={addnewCon} />
                  </ReactModal>
                  <hr />
                  <button className="linkStyle2" onClick={handlenewsletterOpenModal}><AiOutlineMail />  Newsletter </button>
                  <ReactModal 
                    isOpen={state.shownewsletterModal}
                    contentLabel="Create New Newsletter"  
                    onRequestClose={handlenewsletterCloseModal}
                    className="react-confirm-alert-body"
                    overlayClassName="react-confirm-alert-overlay"
                    key="newsletter"
                  >
                  <Cr8Content conType={"newsletter"} close={handlenewsletterCloseModal} addnewCon={addnewCon} />
                  </ReactModal> */}
                </div>
          </li> 
          <li className="dropdown">
              <button className="profbtn" onClick={() => toggleBtn("lgout")}>
                {user ? user.first_name ? user.first_name.charAt(0) : 'A' : 'A'}
                </button>
              <div id="lgout" className="dropdown-content" dir={direc}>
                <a className="linkStyle2" href="/app/account"><RiAccountCircleLine />  {t('link7')}</a>
                <button className="linkStyle2"  onClick={() => logout()}><AiOutlineLogout />  {t('link8')}</button>
              </div>
          </li> 
    </ul>
    <div id="langModal" className="react-confirm-alert-overlay siteStyl" role="presentation" onClick={(event) => {
                let modl = document.getElementById("langModal");
                 if (event.target === modl) {
                    modl.style.display = "none";
                  }
             }}>
                <div className="react-confirm-alert-body langmodal-content">
                    <span className="remove" role="presentation" onClick={() => {
                        let modl = document.getElementById("langModal");
                        modl.style.display = "none";
                    }}>&times;</span>
                    <p>Choose your language</p>
                    <Header close={handleCloseModal} />
                </div>
    </div>
    </NavStyles>
   </>)
}