import * as React from 'react'
import { useLocation } from '@reach/router';
import GlobalStyles from '../styles/GlobalStyles';
import Nav from './Nav';
import SignedNav from './Navigation';
import Footer from './Footer';
import SEO from './SEO';
import useAuth from '../hooks/useAuth';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import allLocales from '../../locales/locales';


export default function Layout({children}) {
    const { isAuthenticated } = useAuth()
    const { pathname } = useLocation();
    const { i18n } = useTranslation();

    React.useEffect(() => {
    if (typeof window !== "undefined" || window.document !== null) {
        document.body.dir = i18n.dir();
      }
    }, [])
    
    // Get language prefix from the URL
    const urlLang = pathname.split('/')[1];

    // Search if locale matches defined, if not set 'en' as default
    const currentLang = Object.keys(allLocales)
        .map(lang => allLocales[lang].path)
        .includes(urlLang)
        ? allLocales[urlLang].locale
        : 'English'; 

    const layoutLeft = { marginTop: '70px', bottom: '50px', top: '0', width: '100%', left: '0' }
    const layoutRight = { marginTop: '70px', bottom: '50px', top: '0', width: '100%', right: '0' }

    if (!isAuthenticated) {
    return(
        <div>
            <GlobalStyles />
            <SEO />
           <Nav lang={currentLang} /> 
           <div style={layoutLeft}> 
            {children}
            </div>
            <Footer />
        </div>
    );} 
    else { 
    return(
        <div>
            <GlobalStyles />
            <SEO />
            <SignedNav lang={currentLang} />
            <div style={layoutLeft}> 
             {children}
            </div>
            <Footer />
        </div>
    );
    }
};
