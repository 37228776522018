import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import {Link, useTranslation} from 'gatsby-plugin-react-i18next';
import { StaticImage } from "gatsby-plugin-image";
import { BiLinkExternal } from "react-icons/bi";
import { FaLinkedin, FaTwitter, FaFacebook, FaInstagram, FaPinterest, FaYoutube } from "react-icons/fa";
import { SiTapas } from "react-icons/si"

export default function Footer() {
    const { t } = useTranslation();

    return (
        <footer style={{width: '100%', border: '1px solid lightgrey', background: 'black', color: 'white', bottom: '0'}}>
            <Container>
                <br />
                <Row> 
                    <Col md="1">
                        <StaticImage src="../assets/images/our_logo_white.png" alt="Magic Author" layout="fullWidth" />
                    </Col>
                    <Col md="3">           
                        <p>&copy; {t('link0')} <sup>&reg;</sup> {new Date().getFullYear()} </p>
                        <p>&nbsp;&nbsp;{t('link9')}</p>
                        <p><a href="https://www.facebook.com/MagicAuthor" target="_blank" rel="noopener noreferrer"> <FaFacebook /></a>&nbsp;
                        <a href="https://twitter.com/magic_author" target="_blank" rel="noopener noreferrer"> <FaTwitter /></a>&nbsp;
                        <a href="https://www.instagram.com/magic_author" target="_blank" rel="noopener noreferrer"> <FaInstagram /></a>&nbsp;
                        <a href="https://www.linkedin.com/company/magic-author" target="_blank" rel="noopener noreferrer"> <FaLinkedin /></a>&nbsp; 
                        <a href="https://in.pinterest.com/magic_author" target="_blank" rel="noopener noreferrer"> <FaPinterest /></a>&nbsp;
                        <a href="https://www.youtube.com/channel/UCwZsoQAALEMsswoXnSkRiXg" target="_blank" rel="noopener noreferrer"> <FaYoutube /></a> </p>
                     </Col>
                     <Col md="3">
                         <Link to='/about-us'>{t('link11')}</Link>
                         <br />
                         <Link to='/pricing'>{t('link12')}</Link>
                         <br />
                         <a href="https://help.magicauthor.com" target="_blank" rel="noopener noreferrer">{t('link13')} <BiLinkExternal /></a>
                         </Col>
                     <Col md="3">
                         <Link to='/privacy-policy'>{t('link14')}</Link>
                         <br />
                         <Link to='/terms-and-conditions'>{t('link15')}</Link>
                         <br />
                         <a href="https://tapas.io/series/WRIMICS/info" target="_blank" rel="noopener noreferrer"><SiTapas /> WRIMICS <BiLinkExternal /></a>
                     </Col>
                     <Col md="2">
                        <a href="https://india.magicauthor.com" target="_blank" rel="noopener noreferrer">{t('link10')} <BiLinkExternal /></a>
                     </Col>
                </Row>
                <br />
            </Container>
        </footer>
    );
};
