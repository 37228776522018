import {Link, useI18next} from 'gatsby-plugin-react-i18next';
import React from 'react';
import allLocales from '../../locales/locales';


function display(urlLang) {
  const currentLang = Object.keys(allLocales)
  .map(lang => allLocales[lang].path)
  .includes(urlLang)
  ? allLocales[urlLang].locale
  : 'English'; 
  return currentLang
}

const Header = ({close}) => {
  const {languages, originalPath} = useI18next();
  return (
    <header className="main-header">
            <ul className="languages">
                    {languages.map((lng) => (
                    <li key={lng}>
                        <Link to={originalPath} language={lng}>
                        {display(lng)}
                        </Link>
                    </li>
                    ))}
            </ul>
            <br />
    </header>
  );
};

export default Header;