module.exports = {
    en: {
      path: 'en',
      locale: 'English',
      default: true,
    },
    es: {
      path: 'es',
      locale: 'español',
    },
    fr: {
      path: 'fr',
      locale: 'français',
    },
    pt: {
      path: 'pt',
      locale: 'Português',
    },
    he: {
      path: 'he',
      locale: 'עברית',
    },
    ar: {
      path: 'ar',
      locale: 'العربية'
    },
    hi: {
      path: 'hi',
      locale: 'हिन्दी',
    },
    bn: {
      path: 'bn',
      locale: 'বাংলা',
    },
    ta: {
      path: 'ta',
      locale: 'தமிழ்',
    },
    ml: {
      path: 'ml',
      locale: 'മലയാളം',
    },
    kn: {
      path: 'kn',
      locale: 'ಕನ್ನಡ',
    },
    mr: {
      path: 'mr',
      locale: 'मराठी',
    },
    sa: {
      path: 'sa',
      locale: 'संस्कृतम्',
    }
  };